import { useUiReadabilityEnhancements } from '@feature-flags/hooks/Storefront/useUiReadabilityEnhancements';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import { selectIsMobile } from '@redux/viewport/selectors';
import { Decorated } from '@typings/vendor';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-scroll';
import AnalyticsConstants from '../../../../../../../constants/analytics';
import VendorAnalytics from '../../../../../../decorators/vendorAnalytics';
import Styles from './styles.scss';

export interface OwnProps {
	shouldHide?: boolean;
	title: string;
}

interface StateProps {
	vendor: Decorated | null;
}

type Props = OwnProps & StateProps;

export const NavItem: React.FC<Props> = ({
	shouldHide = false,
	title,
	vendor,
}) => {
	const { track } = useAnalyticsContext();
	const isMobile = useAppSelector((state) => selectIsMobile(state));
	const isUIReadabilityEnhancementsVariant = useUiReadabilityEnhancements();
	const id = `nav${title}`;

	const onClickHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		event.currentTarget.blur();

		const vendorAnalytics = new VendorAnalytics(vendor || undefined);

		track({
			event: AnalyticsConstants.MINI_NAV_INTERACTION,
			properties: {
				selection: title,
				...vendorAnalytics.baseEventProps(),
			},
		});
	};

	useEffect(() => {
		const observer = new MutationObserver(() => {
			const activeEl = document.querySelector(
				`.${Styles.activeNavLink}`,
			) as HTMLElement;
			if (activeEl) {
				activeEl.scrollIntoView({
					behavior: 'smooth',
					inline: 'center',
					block: 'nearest',
				});
			}
		});

		const container = document.getElementById('nav-bar');
		if (container) {
			observer.observe(container, {
				subtree: true,
				attributes: true,
				attributeFilter: ['class'],
			});
		}

		return () => observer.disconnect();
	}, []);

	return shouldHide ? null : (
		<li className={Styles.navItem} key={id}>
			<Link
				className={Styles.navLink}
				activeClass={Styles.activeNavLink}
				href={`#${id}`}
				to={id}
				smooth
				spy
				isDynamic
				rel={title}
				// The DefinitelyTyped definition for react-scroll is incorrect and doesn't take an event argument.
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				onClick={onClickHandler}
				offset={
					isUIReadabilityEnhancementsVariant ? (isMobile ? -140 : -85) : -49
				}
			>
				{title}
			</Link>
		</li>
	);
};

function mapStateToProps(state: Redux.State): StateProps {
	return {
		vendor: state.vendor.vendor,
	};
}

export default connect<StateProps, {}, OwnProps>(mapStateToProps)(NavItem);
