import { useAppSelector } from '@redux/hooks';
import { Column } from '@xo-union/tk-component-grid';
import { DisplayLink } from '@xo-union/tk-ui-links';
import React, { type FC, useCallback, useMemo, useState } from 'react';
import type { Decorated } from 'types/vendor';

import useAnalyticsContext from '@hooks/useAnalyticsContext';
import AnalyticsConstants from '../../../../../../../constants/analytics';

import { useUiReadabilityEnhancements } from '@feature-flags/hooks/Storefront/useUiReadabilityEnhancements';
import type { Designers as DesignersType } from '@typings/vendor';
import VendorAnalytics from '../../../../../../decorators/vendorAnalytics';
import { DetailAccordionItem } from '../detail/Detail';
import ParentStyles from '../styles.scss';
import Styles from './styles.scss';

const dressTheme = (theme: string) => {
	const dressTypes = [
		'wedding-dresses',
		'flower-girl-dresses',
		'bridesmaid-dress',
		'mother-of-the-bride-dresses',
	];
	return dressTypes.indexOf(theme) !== -1;
};

const jewelryTheme = (theme: string) => {
	const jewelryTypes = [
		'engagement-rings',
		'wedding-rings',
		'tuxedos',
		'wedding-accessories',
		'wedding-jewelry',
	];
	return jewelryTypes.indexOf(theme) !== -1;
};

interface LinksProps {
	designers: Decorated['designers'];
}

const Links = ({ designers }: LinksProps) => {
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const { track } = useAnalyticsContext();
	const vendorAnalytics = useMemo(() => new VendorAnalytics(vendor), [vendor]);

	const designerClick = useCallback(
		(designer: DesignersType) => {
			track({
				event: AnalyticsConstants.CLICK_THROUGH_TO_DESIGNER,
				properties: {
					designerId: designer.odbId,
					designerName: designer.name,
					designerURL: designer.url,
					...vendorAnalytics.baseEventProps(),
				},
			});
		},
		[vendorAnalytics, track],
	);

	return (
		<>
			{designers?.map((designer) => (
				<div className={Styles.linkWrapper} key={designer.odbId}>
					<DisplayLink
						color="primary"
						size="md"
						href={designer.url}
						className={Styles.designerLink}
						onClick={() => designerClick(designer)}
					>
						{designer.name}
					</DisplayLink>
				</div>
			))}
		</>
	);
};

interface Props {
	designers: Decorated['designers'];
}

export const Designers: FC<Props> = (props) => {
	const { designers } = props;
	const designerType = designers?.[0]?.theme;

	const [isOpen, setIsOpen] = useState(true);
	const isUIReadabilityEnhancementsVariant = useUiReadabilityEnhancements();

	const toggleAccordion = () => setIsOpen((prev) => !prev);

	const collectionLabel = useMemo(() => {
		if (designerType && dressTheme(designerType)) {
			return 'Gown Collections';
		}
		if (designerType && jewelryTheme(designerType)) {
			return 'Jewelry Collections';
		}

		return false;
	}, [designerType]);

	if (!designers) {
		return null;
	}

	return isUIReadabilityEnhancementsVariant ? (
		<div
			className={`${ParentStyles.detailsContainer} ${ParentStyles.awardsContainer}`}
		>
			<DetailAccordionItem
				category={collectionLabel || ''}
				isOpen={isOpen}
				toggleAccordion={() => toggleAccordion()}
			>
				<Links designers={designers} />
			</DetailAccordionItem>
		</div>
	) : (
		<Column xs="12" sm="6">
			<div className={Styles.collectionLabel}>{collectionLabel}</div>
			<div>
				<Links designers={designers} />
			</div>
		</Column>
	);
};
