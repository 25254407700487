import { ReadMore } from '@components/read-more/ReadMore';
import { useStorefrontEmptyStates } from '@feature-flags/hooks/Storefront/useStorefrontEmptyStates';
import { useUiReadabilityEnhancements } from '@feature-flags/hooks/Storefront/useUiReadabilityEnhancements';
import { useDetailedPricing } from '@hooks/use-detailed-pricing';
import { useAppSelector } from '@redux/hooks';
import { compose } from '@xo-union/react-css-modules';
import { Column, Row } from '@xo-union/tk-component-grid';
import Icon from '@xo-union/tk-component-icons';
import { Body1, Body2 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import map from 'lodash/map';
import React, { type FC, useState } from 'react';
import type { Decorated } from 'types/vendor';
import { useStorefrontFeatureScannability } from '../../../../hooks/useStorefrontFeatureScannability';
import DetailsHelper from '../../helpers';
import { DetailsEmptyState } from '../DetailsEmptyState';
import { Awards } from '../awards/Awards';
import { Designers } from '../designers/Designers';
import ParentStyles from '../styles.scss';
import Styles from './styles.scss';

interface DetailItemProps {
	category: string;
	details: string[];
	vendor: Decorated;
}
const DetailItem = ({ category, details, vendor }: DetailItemProps) => {
	const shouldShowScannability = useStorefrontFeatureScannability();
	return shouldShowScannability ? (
		<DetailItemScannability
			category={category}
			details={details}
			vendor={vendor}
		/>
	) : (
		<DetailItemDefault category={category} details={details} vendor={vendor} />
	);
};

const GuestCapacity = ({ vendor }: { vendor: Decorated }) => {
	const {
		guestCapacity,
		breadcrumbInfo: { cityUrl },
	} = vendor;

	if (!guestCapacity) {
		return (
			<DetailsEmptyState section="guest capacity" breadcrumbUrl={cityUrl} />
		);
	}

	return <>{guestCapacity}</>;
};

const DetailItemScannability = ({
	category,
	details,
	vendor,
}: DetailItemProps) => {
	return (
		<>
			<Column xs="12" md="4">
				<Body1 bold className={Styles.title}>
					{category}
				</Body1>
			</Column>
			<Column xs="12" md="8">
				<Row className={Styles.list}>
					{details.map((detail: string) => {
						return (
							<Column
								xs={category === 'Guest Capacity' ? '12' : '6'}
								key={`detail_item_${category}_${detail}`}
								className={Styles.itemDetail}
							>
								{category === 'Guest Capacity' ? (
									<GuestCapacity vendor={vendor} />
								) : (
									detail
								)}
							</Column>
						);
					})}
				</Row>
			</Column>
		</>
	);
};

const DetailItemDefault = ({ category, details, vendor }: DetailItemProps) => {
	const [expanded, setExpanded] = useState(false);
	const readMore = details.length > 6;
	if (category === 'Guest Capacity') {
		return (
			<>
				<div className={Styles.detailCategory}>{category}</div>
				<ul className={Styles.list}>
					<li>
						<Body1 className={Styles.detailValue}>{vendor.guestCapacity}</Body1>
					</li>
				</ul>
			</>
		);
	}

	return (
		<>
			<div className={Styles.detailCategory}>{category}</div>
			<ReadMore
				classes={{
					link: Styles.readMoreLink,
					container: Styles.readMoreContainer,
				}}
				expanded={expanded}
				setExpanded={setExpanded}
				showReadMore={readMore}
			>
				<ul className={Styles.list}>
					{details.map((detail: string, index) => {
						if (!expanded && index > 5) {
							return null;
						}

						return (
							<li key={`detail_item_${category}_${detail}`}>
								{category === 'Guest Capacity' ? vendor.guestCapacity : detail}
							</li>
						);
					})}
				</ul>
			</ReadMore>
		</>
	);
};

interface DetailAccordionItemProps {
	category: string;
	isOpen: boolean;
	toggleAccordion: () => void;
	details?: string[];
	children?: JSX.Element;
}

export const DetailAccordionItem = ({
	category,
	details,
	isOpen,
	toggleAccordion,
	children,
}: DetailAccordionItemProps) => {
	const vendor = useAppSelector((state) => state.vendor.vendor) as Decorated;
	const shouldShowScannability = useStorefrontFeatureScannability();
	const columns = details ? (details.length > 3 ? 4 : details.length) : 4;

	if (!details && !children) return <></>;

	return (
		<div className={ParentStyles.accordionContainer}>
			<button
				type="button"
				className={ParentStyles.accordionHeader}
				onClick={toggleAccordion}
				aria-expanded={isOpen}
			>
				<Body1 bold>{category}</Body1>
				<Icon name={isOpen ? 'caret_up' : 'caret_down'} size="md" />
			</button>

			{isOpen && (
				<div
					className={classNames(
						ParentStyles.accordionContent,
						ParentStyles[`columns${columns}`],
					)}
				>
					{details && details.length > 0
						? details.map((detail: string) => (
								<Body2
									key={`detail_item_${category}_${detail}`}
									className={ParentStyles.detailText}
								>
									<Icon name="checkmark" size="sm" />
									{shouldShowScannability && category === 'Guest Capacity' ? (
										<GuestCapacity vendor={vendor} />
									) : (
										detail
									)}
								</Body2>
							))
						: shouldShowScannability &&
							category === 'Guest Capacity' && (
								<GuestCapacity vendor={vendor} />
							)}

					{!details && children}
				</div>
			)}
		</div>
	);
};

interface DetailsProps {
	vendor: Decorated;
}

const Details = ({ vendor }: DetailsProps) => {
	const shouldShowScannability = useStorefrontFeatureScannability();
	const isEmptyState = useStorefrontEmptyStates();
	const isUIReadabilityEnhancementsVariant = useUiReadabilityEnhancements();

	const flattenedDetails: Record<string, string[]> =
		DetailsHelper.detailsFlattener(vendor) as Record<string, string[]>;

	const categories = Object.keys(flattenedDetails);
	const [openCategory, setOpenCategory] = useState(categories[0]);

	const handleToggle = (category: string) => {
		setOpenCategory((prevCategory) =>
			prevCategory === category ? '' : category,
		);
	};

	if (shouldShowScannability) {
		(flattenedDetails as { 'Business Attributes'?: string })[
			'Business Attributes'
		] = undefined;

		(flattenedDetails as { 'Guest Capacity'?: [string] })['Guest Capacity'] =
			isEmptyState && vendor.categoryCode === 'REC' ? [''] : undefined;
	}

	const facetsLength = Object.keys(flattenedDetails).length;

	if (!facetsLength) {
		return null;
	}

	return (
		<>
			{isUIReadabilityEnhancementsVariant ? (
				<div className={ParentStyles.detailsContainer}>
					{categories.map((category) => (
						<DetailAccordionItem
							key={category}
							category={category}
							details={flattenedDetails[category]}
							isOpen={category === openCategory}
							toggleAccordion={() => handleToggle(category)}
						/>
					))}
				</div>
			) : (
				map(flattenedDetails, (details: string[], category) => {
					if (details !== undefined) {
						return shouldShowScannability ? (
							<>
								<Row className={Styles.detailSection}>
									<DetailItem
										category={category}
										details={details}
										vendor={vendor}
									/>
								</Row>
								<div className={Styles.divider} />
							</>
						) : (
							<Column
								xs="12"
								md="6"
								className={Styles.detailWrapper}
								key={`detail_column_${category}`}
							>
								<DetailItem
									category={category}
									details={details}
									vendor={vendor}
								/>
							</Column>
						);
					}
				})
			)}
		</>
	);
};

interface Props {
	vendor: Decorated;
}

export const Detail: FC<Props> = (props) => {
	const { vendor } = props;
	const shouldShowScannability = useStorefrontFeatureScannability();
	const isUIReadabilityEnhancementsVariant = useUiReadabilityEnhancements();
	const isInDetailedPricing = useDetailedPricing();
	const isMobile = useAppSelector((state) => state.viewport.isMobile);

	const hasDesigners = Boolean(
		vendor.designers?.length && vendor.designers.length > 0,
	);
	const hasAwards = Boolean(vendor.awards?.length && vendor.awards.length > 0);

	return shouldShowScannability ? (
		<>
			{<Details vendor={vendor} />}
			{hasDesigners && <Designers designers={vendor.designers} />}
			{isUIReadabilityEnhancementsVariant ? (
				hasAwards && (
					<Awards vendor={vendor} fullWidth={true} carousel={isMobile} />
				)
			) : (
				<Row>
					{hasAwards && (
						<Awards vendor={vendor} fullWidth={true} carousel={isMobile} />
					)}
				</Row>
			)}
		</>
	) : isUIReadabilityEnhancementsVariant ? (
		<>
			<Details vendor={vendor} />
			{hasDesigners && <Designers designers={vendor.designers} />}
			{hasAwards && !isInDetailedPricing && <Awards vendor={vendor} />}
		</>
	) : (
		<Row classes={compose({ row: Styles.detailsRow })}>
			<Details vendor={vendor} />
			{hasDesigners && <Designers designers={vendor.designers} />}
			{hasAwards && !isInDetailedPricing && <Awards vendor={vendor} />}
		</Row>
	);
};
