import { compact } from 'lodash';
import React, { type FC } from 'react';
import AnalyticsConstants from '../../../../../../../constants/analytics';
import TrackableLink from '../../../../../../components/trackable_link';
import VendorAnalytics from '../../../../../../decorators/vendorAnalytics';

import { useUiReadabilityEnhancements } from '@feature-flags/hooks/Storefront/useUiReadabilityEnhancements';
import { useLiteStorefront } from '@hooks/use-lite-storefront';
import { useAppSelector } from '@redux/hooks';
import type { State } from '@typings/redux';
import { useStorefrontFeatureScannability } from '../../../../hooks/useStorefrontFeatureScannability';
import Styles from './styles.scss';

export const ContactInfo: FC = () => {
	const memberId = useAppSelector((state: State) =>
		state.membership.member ? state.membership.member.id : '',
	);
	const vendor = useAppSelector((state: State) => state.vendor.vendor);
	const viewport = useAppSelector((state: State) => state.viewport);
	const isUIReadabilityEnhancementsVariant = useUiReadabilityEnhancements();
	const isLiteStorefront = useLiteStorefront();
	const shouldShowScannability = useStorefrontFeatureScannability();

	const addressAndOrServiceArea = () => {
		const { address, serviceArea } = vendor;
		const formattedAddress = compact([
			address.address,
			address.city,
			address.state,
		]).join(', ');

		if (address.isPublic && serviceArea) {
			return `${formattedAddress} | ${serviceArea}`;
		}

		if (!address.isPublic && serviceArea) {
			return serviceArea;
		}

		return formattedAddress;
	};

	const formattedPhoneNumber = () => {
		const { phone } = vendor;
		const phoneWithDashes =
			!!phone && phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
		const cleanExtension = formatExtension();
		const formattedPhone = cleanExtension
			? `${phoneWithDashes} ext. ${cleanExtension}`
			: phoneWithDashes;
		return formattedPhone || null;
	};

	const formatExtension = () => {
		const { extension } = vendor;
		return !!extension && extension.replace(/\D/g, '');
	};

	const phoneNumber = () => {
		if (viewport.isMobile || viewport.lessThan.large) {
			const vendorAnalytics = new VendorAnalytics(vendor);
			const trackingDataCollection = [
				{
					event: AnalyticsConstants.CALL_VENDOR,
					properties: {
						memberId,
						phoneNumber: formattedPhoneNumber(),
						...vendorAnalytics.baseEventProps(),
					},
				},
			];

			const { phone } = vendor;
			const cleanExtension = formatExtension();
			const phoneWithExtension = cleanExtension
				? `tel:+1${phone},${cleanExtension}`
				: `tel:+1${phone}`;

			return (
				<TrackableLink
					websiteUrl={phoneWithExtension}
					itemprop="telephone"
					text={formattedPhoneNumber()}
					title="phone"
					icon={isUIReadabilityEnhancementsVariant ? 'phone' : null}
					iconSize="lg"
					trackingData={trackingDataCollection}
				/>
			);
		}
		return formattedPhoneNumber();
	};

	return (
		<div className={Styles.contactInfo}>
			{!shouldShowScannability && <span>{addressAndOrServiceArea()}</span>}
			{!isLiteStorefront && (
				<span className={Styles.phoneNumber} itemProp="telephone">
					{phoneNumber()}
				</span>
			)}
		</div>
	);
};

export default ContactInfo;
