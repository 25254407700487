import { useTrackStorefrontImpression } from '@hooks/useTrackStorefrontImpression';
import { H3 } from '@xo-union/tk-ui-typography';
import React, { type FC, useMemo } from 'react';
import { Amenities } from './components/amenities/Amenities';
import { Detail } from './components/detail/Detail';

import { useAppSelector } from '@redux/hooks';
import type { Decorated } from 'types/vendor';
import type { NavItemProps } from '../nav/types';
import Styles from './styles.scss';

import { HighlightedRequest } from '@components/highlightedRequest/highlightedRequest';
import { useStorefrontEmptyStates } from '@feature-flags/hooks/Storefront/useStorefrontEmptyStates';
import { useUiReadabilityEnhancements } from '@feature-flags/hooks/Storefront/useUiReadabilityEnhancements';
import { useStorefrontFeatureScannability } from '../../hooks/useStorefrontFeatureScannability';

interface HeaderProps {
	vendor: Decorated;
}

const Header = ({ vendor }: HeaderProps) => {
	const detailsTrackingRef = useTrackStorefrontImpression('What we offer');
	const isUIReadabilityEnhancementsVariant = useUiReadabilityEnhancements();

	const text = useMemo(() => {
		if (isUIReadabilityEnhancementsVariant) return 'What to expect';
		if (vendor.categoryCode === 'REC') {
			return 'Amenities + details';
		}

		return 'Details';
	}, [vendor, isUIReadabilityEnhancementsVariant]);

	return (
		<H3 className={Styles.title} ref={detailsTrackingRef}>
			{text}
		</H3>
	);
};

interface ContentProps {
	vendor: Decorated;
}

const Content = ({ vendor }: ContentProps) => {
	const shouldShowScannability = useStorefrontFeatureScannability();
	const isEmptyState = useStorefrontEmptyStates();

	const venueAmenities = useAppSelector(
		(state) => state.settings.venueAmenities,
	);

	return (
		<div className={Styles.contentContainer}>
			<Amenities
				vendor={vendor}
				venueAmenities={venueAmenities}
				shouldShowScannability={shouldShowScannability}
				shouldShowEmptyState={isEmptyState}
			/>
			<Detail vendor={vendor} />
		</div>
	);
};

export const Details: FC<NavItemProps> = () => {
	const vendor = useAppSelector(
		(state) => state.vendor.vendor as unknown as Decorated,
	);

	const shouldShowScannability = useStorefrontFeatureScannability();

	return (
		<div>
			<Header vendor={vendor} />
			<Content vendor={vendor} />
			{shouldShowScannability && (
				<HighlightedRequest
					messageText="Any questions?"
					buttonText="Start a conversation"
					initiator="Details CTA"
					hideIfLead={true}
				/>
			)}
		</div>
	);
};
