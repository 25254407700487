import { useUiReadabilityEnhancements } from '@feature-flags/hooks/Storefront/useUiReadabilityEnhancements';
import type { IconName } from '@xo-union/dist-iconography';
import { Column, Row } from '@xo-union/tk-component-grid';
import Icon from '@xo-union/tk-component-icons';
import { Body1 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import { difference, map } from 'lodash';
import React, { useState } from 'react';
import type { VenueAmenity } from 'types/settings';
import type { Decorated } from 'types/vendor';
import VENUE_AMENITIES from '../../constants/venue_amenities';
import DetailsHelper from '../../helpers';
import { DetailsEmptyState } from '../DetailsEmptyState';
import { DetailAccordionItem } from '../detail/Detail';
import ParentStyles from '../styles.scss';
import Styles from './styles.scss';

interface AmenitiesProps {
	vendor: Decorated;
	venueAmenities?: VenueAmenity[];
	shouldShowScannability: boolean;
	shouldShowEmptyState: boolean;
}

export const Amenities: React.FC<AmenitiesProps> = ({
	vendor,
	venueAmenities = [],
	shouldShowScannability,
	shouldShowEmptyState,
}) => {
	const [isOpen, setIsOpen] = useState(true);
	const isUIReadabilityEnhancementsVariant = useUiReadabilityEnhancements();

	const toggleAccordion = () => setIsOpen((prev) => !prev);

	const renderAmenities = (
		amenities: string[],
		icon: IconName,
		showIcons = true,
		shouldShowScannability?: boolean,
		shouldShowEmptyState?: boolean,
		breadcrumbUrl?: string,
	) => {
		if (!amenities.length && shouldShowEmptyState) {
			return isUIReadabilityEnhancementsVariant ? (
				<DetailsEmptyState breadcrumbUrl={breadcrumbUrl} section="amenities" />
			) : (
				<Column xs="12">
					<DetailsEmptyState
						breadcrumbUrl={breadcrumbUrl}
						section="amenities"
					/>
				</Column>
			);
		}

		return isUIReadabilityEnhancementsVariant ? (
			<div className={Styles.amenitiesGrid}>
				{amenities.map((amenity) => (
					<div key={amenity.toLowerCase()} className={Styles.amenitiesWrapper}>
						{showIcons && (
							<Icon name={icon} className={Styles[icon]} size="md" />
						)}
						<div data-testid={`test-${icon}`} className={Styles.amenity}>
							{amenity}
						</div>
					</div>
				))}
			</div>
		) : (
			amenities.map((amenity) => (
				<Column
					xs="6"
					md="6"
					key={amenity.toLowerCase()}
					className={Styles.amenitiesWrapper}
				>
					{!shouldShowScannability && (
						<Icon name={icon} className={Styles[icon]} size="md" />
					)}
					<div data-testid={`test-${icon}`} className={Styles.amenity}>
						{amenity}
					</div>
				</Column>
			))
		);
	};

	const renderAvailableAmenities = () => {
		const amenityNames = map(vendor.amenities, 'name');
		return renderAmenities(
			amenityNames,
			'checkmark',
			true,
			shouldShowScannability,
			shouldShowEmptyState,
			vendor.breadcrumbInfo.cityUrl,
		);
	};

	const renderRemainingAmenities = () => {
		const amenityIds = map(vendor.amenities, 'id');
		const remainingAmenities = difference(VENUE_AMENITIES, amenityIds);
		const remainingAmenityNames = DetailsHelper.findMatchingFacets(
			venueAmenities,
			remainingAmenities,
		);
		return renderAmenities(
			remainingAmenityNames,
			'close',
			true,
			shouldShowScannability,
		);
	};

	if (vendor.categoryCode !== 'REC') {
		return null;
	}

	return isUIReadabilityEnhancementsVariant ? (
		<div className={ParentStyles.detailsContainer}>
			<DetailAccordionItem
				category="Amenities"
				isOpen={isOpen}
				toggleAccordion={toggleAccordion}
			>
				<>
					{renderAvailableAmenities()}
					{!shouldShowScannability && renderRemainingAmenities()}
				</>
			</DetailAccordionItem>
		</div>
	) : (
		<>
			<Row
				className={classNames({
					[Styles.detailSection]: shouldShowScannability,
				})}
			>
				{shouldShowScannability && (
					<Column xs="12" md="4">
						<Body1 bold className={Styles.title}>
							Amenities
						</Body1>
					</Column>
				)}
				<Column
					xs="12"
					md={shouldShowScannability ? '8' : '12'}
					className={Styles.amenities}
				>
					<Row>
						{renderAvailableAmenities()}
						{!shouldShowScannability && renderRemainingAmenities()}
					</Row>
				</Column>
			</Row>
			{shouldShowScannability && <div className={Styles.divider} />}
		</>
	);
};
